export const locale = {
    lang: 'en',
    data: {
        NAV: {
            'MANAGE-ADMIN': {
                TITLE: 'Manage Admin',
            },
            'MANAGE-RESTAURANT': {
                TITLE: 'Manage Restaurant',
            },
            'IMPORT-MODULE': {
                TITLE: 'Import',
            },
            'EXPORT-MODULE': {
                TITLE: 'Export',
            },
            'UPLOAD-MANUAL': {
                TITLE: 'Upload Manual',
            },

            'PRODUCT-RANGE': {
                TITLE: 'Product range',
            },
            ASSORTMENT: {
                TITLE: 'Assortment',
            },
            CHECKLIST: {
                TITLE: 'Checklist',
            },
            LOCATION: {
                TITLE: 'Location',
            },
            CARDS: {
                TITLE: 'Cards',
            },
            STORES: {
                TITLE: 'Stores',
            },
            SUPPLIERS: {
                TITLE: 'Suppliers',
            },
        },
        ADMIN: {
            COMPANY_NAME: "Company name"
        },
        RESTAURANT: {
            SEARCH_RESTAURANT: 'Search Restaurant',
            SEARCH_MANUAL: 'Search Manual',
            MANAGE_RESTAURANT: 'Restaurants',
            UPLOAD_MANUAL: 'Upload Manual',
            OWNER_OR_ADMIN: 'Owner/Admin',
            ADD_RESTAURANT: "Add Restaurant",
            DELETE_RESTAURANT_MSG: "Are sure you want to delete restaurant?",
            RESTAURANT_DELETED_MSG: "Restaurant deleted!!",
            EMAIL:'Email'
        },
        ACTION: "Actions",
        NAME: "Name",
        ADD: "Add",
        CANCEL: "Cancel",
        UPDATE: "Update",
        IMAGE: "Image",
        IMAGE_SIZE_VALIDATE:
        'PDF size exceeds 5 MB, Please select less than 5 MB.',
        IMAGE_UPLOAD_SUCCESSFULLY:'Image uploaded successfully',
        UPLOAD_SUCCESS : 'File uploaded Successfully',
        SOMETHING_WENT_WRONG: 'Something went wrong',
        MANUAL_ADD_SUCCESSFULLY : 'Manual added successfully',
        MANUAL_UPDATED_SUCCESSFULLY : 'Manual updated successfully',
        MANUAL_DELETE_SUCCESSFULLY : 'Manual deleted successfully',
        FILE_IMPORTED_SUCCESSFULLY: 'File imported successfully'
    },
};
