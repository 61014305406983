import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
    userInfo: any;
    constructor() {}
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (
            localStorage.getItem('currentUser') &&
            localStorage.getItem('currentUser') !== null
        ) {
            this.userInfo = JSON.parse(localStorage.getItem('currentUser'));
            const token = this.userInfo.data.token;
            if (token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `${token}`
                    }
                });
            }
        }
        return next.handle(request);
    }
}
