import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthGuardService } from './auth-guard.service';

import { ToasterService } from './toaster.service';
import { ToolbarComponent } from '../../../layout/components/toolbar/toolbar.component';
import { GeneralError } from 'assets/constant';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private toaster: ToasterService,
        private router: Router,
        private authenticationService: AuthenticationService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if (err.status === 401) {
                    console.log('4')
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    this.router.navigate(['/pages/auth/login']);
                    location.reload();
                    this.toaster.error(GeneralError.somethingWentWrong);
                }

                const error = err.error.message || err.statusText;
                return throwError(err);
            })
        );
    }
}
