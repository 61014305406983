import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'businesslist',
        title: 'Manage Admin',
        translate: 'NAV.MANAGE-ADMIN.TITLE',
        type: 'item',
        icon: 'layers',
        url: '/apps/manage-admin'
    },
    // {
    //     id: 'businesslist',
    //     title: 'Manage Restaurant',
    //     translate: 'NAV.MANAGE-ADMIN.TITLE',
    //     type: 'item',
    //     icon: 'store',
    //     url: '/apps/manage-restaurant'
    // },
    {
        id: 'importModule',
        title: 'Import',
        translate: 'NAV.IMPORT-MODULE.TITLE',
        type: 'item',
        icon: 'import_export',
        url: '/apps/import-module'
    },
    {
        id: 'exportModule',
        title: 'Export',
        translate: 'NAV.EXPORT-MODULE.TITLE',
        type: 'item',
        icon: 'import_export',
        url: '/apps/export-module'
    },
    {
        id: 'uploadManual',
        title: 'Upload Manual',
        translate: 'NAV.UPLOAD-MANUAL.TITLE',
        type: 'item',
        icon: 'flag',
        url: '/apps/upload-manual'
    },
    // {
    //     id: 'list-product-range',
    //     title: 'List product range',
    //     translate: 'NAV.PRODUCT-RANGE.TITLE',
    //     type: 'item',
    //     icon: 'layers',
    //     url: '/apps/product-range/list-product-range'
    // }
    // {
    //     id: 'assortment',
    //     title: 'Assortment',
    //     translate: 'NAV.ASSORTMENT.TITLE',
    //     type: 'item',
    //     icon: 'layers',
    //     url: '/assortment/list-assortment',
    // },
    // {
    //     id: 'list-check-list',
    //     title: 'List checklist',
    //     translate: 'NAV.CHECKLIST.TITLE',
    //     type: 'item',
    //     icon: 'layers',
    //     url: 'check-list/list-check-list'
    // },
    // {
    //     id: 'list-location',
    //     title: 'List location',
    //     translate: 'NAV.LOCATION.TITLE',
    //     type: 'item',
    //     icon: 'layers',
    //     url: 'location/list-location'
    // },
    // {
    //     id: 'list-cards',
    //     title: 'List cards',
    //     translate: 'NAV.CARDS.TITLE',
    //     type: 'item',
    //     icon: 'layers',
    //     url: 'cards/list-cards'
    // },
    // {
    //     id: 'list-stores',
    //     title: 'List stores',
    //     translate: 'NAV.STORES.TITLE',
    //     type: 'item',
    //     icon: 'layers',
    //     url: 'stores/list-stores'
    // },
    // {
    //     id: 'list-suppliers',
    //     title: 'List suppliers',
    //     translate: 'NAV.SUPPLIERS.TITLE',
    //     type: 'item',
    //     icon: 'layers',
    //     url: 'suppliers/list-suppliers'
    // }
];
