import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { InterceptorService } from './main/common/services/interceptor';
import {
    AuthGuardService,
    LoginGuardService,
} from './main/common/services/auth-guard.service';
import { ErrorInterceptor } from './main/common/services/error.interceptor';

const routes: Routes = [
    {
        path: 'apps',
        loadChildren: './main/apps/apps.module#AppsModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'pages',
        loadChildren: './main/pages/pages.module#PagesModule',
        canActivate: [LoginGuardService]
    },
    {
        path: '**',
        redirectTo: 'apps',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        AuthGuardService, LoginGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ]
})
export class AppRoutingModule {}
