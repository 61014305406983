import { Component, OnInit, ViewChild, Input, ElementRef, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Cropper from 'cropperjs';

@Component({
  selector: 'app-fuse-imagecropper',
  templateUrl: './fuse-imagecropper.component.html',
  styleUrls: ['./fuse-imagecropper.component.scss']
})
export class FuseImagecropperComponent implements OnInit {
  imageSource: any;
  public constructor(
    public dialogRef: MatDialogRef<FuseImagecropperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.imageDestination = '';
    this.imageSource = data;
    dialogRef.disableClose = true;
  }
  @ViewChild('image')
  public imageElement!: ElementRef;

 
  public imageDestination: string;
  cropper!: Cropper;

  // tslint:disable-next-line:use-lifecycle-interface
  public ngAfterViewInit() {
    this.cropper = new Cropper(this.imageElement.nativeElement, {
        zoomable: true,
        scalable: true,
        aspectRatio: 1 / 1,
        cropBoxResizable: false,
        zoomOnWheel: true,
        zoomOnTouch: true,
        dragMode: <any> 'move',
        crop: () => {
            const canvas = this.cropper.getCroppedCanvas({
              fillColor: '#ffffff'
            });
            const context = canvas.getContext('2d');
            const imageUrl = canvas.toDataURL('image/jpeg', 1);
            canvas.height = (canvas.width > 500) ? 500 : canvas.height;
            canvas.width = (canvas.width > 500) ? 500 : canvas.width;            
            const base_image = new Image();
            base_image.src = imageUrl;
            base_image.onload = function(){
              context.drawImage(base_image, 0, 0, canvas.height, canvas.width);
            }
            setTimeout(() => {
              this.imageDestination = canvas.toDataURL('image/jpeg', 1);
            }, 200)
        }
    });
}
getImageUrl(){
  this.dialogRef.close({data : this.imageDestination});
}
  ngOnInit(): void {
  }

}
