export const locale = {
    lang: 'nl',
    data: {
        NAV: {
            'MANAGE-BUSINESS': {
                TITLE: 'Beheer zaken',
            },
            'IMPORT-MODULE': {
                TITLE: 'Importeren',
            },
            'UPLOAD-MANUAL': {
                TITLE: 'Handleiding uploaden',
            },
            'EXPORT-MODULE': {
                TITLE: 'Export',
            },
        },
        IMAGE_SIZE_VALIDATE:
        'Beeldgrootte groter dan 5 MB, selecteer minder dan 5 MB.',
        UPLOAD_SUCCESS : 'Bestand succesvol geüpload',
        SOMETHING_WENT_WRONG: 'er is iets fout gegaan',
        MANUAL_ADD_SUCCESSFULLY : 'Handleiding succesvol toegevoegd',
        MANUAL_UPDATED_SUCCESSFULLY : 'Handleiding succesvol bijgewerkt',
        MANUAL_DELETE_SUCCESSFULLY : 'Handmatig verwijderd',
        IMAGE_UPLOAD_SUCCESSFULLY:'Afbeelding succesvol geupload',
        FILE_IMPORTED_SUCCESSFULLY: 'Bestand succesvol geïmporteerd'
    },
};
