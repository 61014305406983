import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseImagecropperComponent } from './fuse-imagecropper/fuse-imagecropper.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButton, MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [
    FuseImagecropperComponent
  ],
  entryComponents: [
    FuseImagecropperComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule
  ],
  exports: [
    FuseImagecropperComponent
  ],
})
export class ImageCropperModule { }
