// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    general_const:
    {
        'BASE_URL': 'https://admin-quickwalk.focalat.com/',
        'Version': 'V1',
        // SERVER_URL: 'https://quickwalk-api.viitorcloud.in:4000/master-admin/',
        // SERVER_PATH: 'https://quickwalk-api.viitorcloud.in:4000/',
        // 'SERVER_URL': 'https://quickwalk-dev.focalat.com/master-admin/',
        // 'SERVER_PATH': 'https://quickwalk-dev.focalat.com/',
        // AWS_UPLOAD_URL: 'https://quickwalkmediaprocess-dev.focalat.com/',
        AWS_LOAD_URL: 'https://quickwalk-vc.s3.us-east-1.amazonaws.com/',
        // SERVER_URL: 'http://192.168.1.215:4000/master-admin/',
        // SERVER_PATH: 'http://192.168.1.215:4000/',
        // AWS_UPLOAD_URL: 'http://192.168.1.215:4003/'
        SERVER_URL: 'https://api-quickwalk.focalat.com/master-admin/',
        SERVER_PATH: 'https://api-quickwalk.focalat.com/',
        AWS_UPLOAD_URL: 'https://media-process.focalat.com/'

    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
