import { environment } from '../environments/environment';
// import { constants } from 'http2';

export class Constants {
    public static project_name = 'Digital Learning';
    public static SERVER_URL = environment.general_const.SERVER_URL;
    public static SERVER_ADMIN_URL = 'https://quickwalk-dev.focalat.com//admin/';
    public static BASE_URL = 'http://localhost:4200';
    public static S3_URL = environment.general_const.AWS_UPLOAD_URL;
    public static Upload_URL = 'http://192.168.1.47:4000/';
    public static image_size = 2;
    public static fileSize = 5;
    public static image_types_array = ['jpg', 'png', 'gif', 'jpeg'];
    public static image_accept_types = ['.jpg', '.png', '.gif', '.jpeg'];
    public static import_excel = ['.xlsx', '.xls'];
    public static video_size = 20;
    public static video_types_array = ['mp4'];

    public static Status = [
        { status_value: '0', status_type: 'In-Active' },
        { status_value: '1', status_type: 'Active' }
    ];

    public static AdminType = [
        { type_value: 0, type: 'Super Admin' },
        { type_value: 1, type: 'Business Admin' }
    ];

    public static statusType = [
        { value: '1', viewValue: 'Requested' },
        { value: '2', viewValue: 'Active' },
        { value: '3', viewValue: 'InActive' }
    ];

    public static pageLength = 10;
    // public static defaultDateFormat = 'y-MM-dd hh:mm:ss';
    public static defaultDateFormat = 'y-MM-dd';

    public static companyStatusType = [
        { value: '1', viewValue: 'Prospect' },
        { value: '2', viewValue: 'Client' },
        { value: '3', viewValue: 'Neither' },
    ];

    public static applicationStatusType = [
        { value: '1', viewValue: 'Requested' },
        { value: '2', viewValue: 'Active' },
        { value: '3', viewValue: 'Inactive' },
    ];
    public static file_accept_types = ['.xlsx', '.xls', '.csv'];
}

export interface Status {
    value: string;
    viewValue: string;
}

export class LoginToasterMessage {
    public static loginSuccess = 'Successfully logged in.';
    public static logoutSuccess = 'Successfully logged Out.';
    public static errorLogin = 'You are not authorized user.';
    public static blankInputError = 'Please enter valid credential.';
    public static userPasswordError = 'Please enter valid email or password.';
}
export class ForgotPasswordToasterMessage {
    public static successfullySent =
        'We have sent an email message to your email address, Please check your email messages.';
    public static emailNotExistError =
        'Email address is not available, choose a different email address.';
}

export class SignUpToasterMessage {
    public static successfullySignUp = 'Successfully signUp in.';
    public static errorSignUp = 'Please enter valid details.';
    public static alreadyExit = 'User Already exists';
}

export class ResetPasswordToasterMessage {
    public static successfullyUpdate = 'Password Reset successfully.';
}

export class GeneralError {
    public static QUERYEXCEPTION = 'Query exception!!!';
    public static somethingWentWrong = 'Something Went Wrong.';
    public static dataNotFound = 'Data not found.';
    public static fillingError = 'Please Enter Proper Question';
    public static emailExist = 'Email address is already exist.';
    public static fillPassword = 'Please enter the password.';
    public static fillConfirmPassword = 'Please enter the confirm password';
    public static passwordMismatch = 'Confirm password will be same as password.';
    public static powerPassword = 'Please enter the power password.';
}
export class BusinessToasterMessage {
    public static businessAddSuccess = 'Business added successfully.';
    public static businessUpdateSuccess = 'Business updated successfully.';
    public static businessDeleteSuccess = 'Business deleted successfully.';
    public static businessAlreadyExist = 'Business already exists. Please try another email';
}

export class ProfileToasterMessage {
    public static profileUpdate = 'Profile updated successfully.';
    public static powerPasswordSave = 'Power Password saved successfully.';
    public static powerPasswordUpdate = 'Power Password status updated successfully.';
    public static defineEmailSave = 'Email saved successfully.';
}
