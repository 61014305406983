import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(
            JSON.parse(localStorage.getItem('currentUser'))
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    // public get currentUserValue(): User {
    //     return this.currentUserSubject.value;
    // }
    currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(loginAPI, userDetail): Observable<User> {
        return this.http.post<User>(loginAPI, userDetail).pipe(
            map(userInfo => {
                // login successful if there's a jwt token in the response
                if (userInfo) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem(
                        'currentUser',
                        JSON.stringify(userInfo)
                    );
                    this.currentUserSubject.next(userInfo);
                }
                return userInfo;
            })
        );
    }

    setUserData(userInfo): any {
        this.currentUserSubject.next(userInfo);
    }

    logout(): any {
        // remove user from local storage to log user out
        localStorage.removeItem('redirectioPatch');
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}

export class User {
    id: number;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    token: string;
}
