import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BusinesslistService {

    /**
     * Constructor
     * @param _http HttpClient
     */
    constructor(
        public _http: HttpClient,
    ) { }

    /**
     * Get Business List Data
     * @param getBusinessListApi Api
     * @param inputParam  Parameters
     */
    getBusinessList(getBusinessListApi: string, inputParam: any): Observable<any> {
        return this._http.get<any>(getBusinessListApi, inputParam);
    }

    /**
     * Delete business admin
     * @param deleteApi Api
     * @param inputParam Parameters
     */
    deleteBusiness(deleteApi: string, inputParam: any): Observable<any> {
        return this._http.post<any>(deleteApi, inputParam);
    }
}
